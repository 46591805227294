
import { defineComponent } from 'vue';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import moment from 'moment';
export default defineComponent({
  name: 'seip-claim-1-approved-bill-list',
  mixins: [apiEndpoint],
  components: {
    ElNotification,
  },
  data() {
    return {
      bill: {
        tranche_id: '',
        entity_id: '',
        entity_contract_id: '',
        institute_info_id: '',
      } as any,
      tranches: [],
      entityInfos: [],
      contractInfos: [],
      instituteInfos: [],
      allBillNo: [],
      billList: [],
      moment: '' as any,
      entity_short_name: '' as any,
      load: false,
      loading: false,
      showBillList: false,
      showInstitute: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.moment = moment;
    await this.getTranche();
    await this.getAssociation();
    this.bill.tranche_id = this.$route.params.tranche_id;
    this.bill.entity_id = this.$route.params.entity_id;
    this.entity_short_name = this.$route.params.entity_short_name;

    if (this.bill.tranche_id && this.bill.entity_id) {
     if(this.bill.entity_id == 19){
        await this.getInstitute();
        this.showInstitute = true;
      }else{
        this.bill.institute_info_id = '';
        await this.getBillList();
      }
      await this.getEntityContract();
    }
  },
  methods: {
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityContract() {
      this.load = true;
      await ApiService.get(
        'entity/contract/list?entity_info_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id
      )
        .then((response) => {
          this.contractInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstitute() {
      this.load = true;
      this.bill.institute_info_id = '';
      await ApiService.get(
         this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.bill.entity_id
      )
        .then((response) => {
          this.instituteInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBillList() {
      if(this.bill.entity_id == 19 && this.bill.institute_info_id ==''){
        Swal.fire({
          title: 'Warning!',
          html: 'Please select institute.',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        });
      }else{
        this.load = true;
        await ApiService.get(
          'bill/claim_1/seip_bill_list?entity_id=' +
            this.bill.entity_id +
            '&tranche_id=' +
            this.bill.tranche_id +
            '&entity_contract_id=' +
            this.bill.entity_contract_id +
            '&institute_info_id=' +
             this.bill.institute_info_id +
            '&active_status=3'
        )
          .then((response) => {
            this.billList = response.data.data;
            this.showBillList = true;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    async entityNameChange(id) {
      if(id == 19){
         this.showInstitute = true;
      }else{
         this.showInstitute = false;
      }
      this.billList = [];
      this.showBillList = false;
      this.getInstitute();
      this.getEntityContract();
      await ApiService.get('entity/show/' + id)
        .then((response) => {
          this.entity_short_name = response.data.data.entity_short_name;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async downloadPdf(bill) {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      formData.set('entity_id', this.bill.entity_id);
      formData.set('tranche_id', this.bill.tranche_id);
      formData.set('entity_contract_id', this.bill.entity_contract_id);
      formData.set('institute_info_id', this.bill.institute_info_id);
      formData.set('bill_sequence_no', bill.bill_sequence_no);
      await ApiService.post('bill/claim_1/claim_1_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {},
});
